import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingComponent from '../../components/common/LoadingComponent';
import styled, { ThemeConsumer } from 'styled-components';
import HeadphonesIcon from '../../images/Claims-Page/headphones.svg';
import TimesCircleIcon from '../../images/Claims-Page/Time_Circle.svg';
import { PrimaryButton } from '../../components/common/Buttons';
import { ButtonText } from '../../components/common/Text';
import { FaStar } from 'react-icons/fa';
import { postFeedbackDetails } from '../../services';
import CheckIcon from '../../images/Claims-Page/Checked.svg';
import FeedBackSubmitCheckIcon from '../../images/Claims-Page/feedback_submittedicon.svg';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow-y: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 18px 14px;
  background: #fff;
`;

const colors = {
  orange: '#FFA800',
  grey: '#F0F3F6',
};

class ClaimFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      textarea: '',
      starValue: 0,
      starHoverValue: 0,
      claimInfo: [],
      reason: [],
      feedbackId: [],
      success: false,
    };
    console.log('feedback-props', props);
  }

  componentDidMount() {
    this.setState({
      claimInfo: this.props.location.state.claimInfo,
      reason: this.props.location.state.feedback,
    });
  }

  handleClick = (value) => {
    this.setState({
      starValue: value,
    });
  };

  handleHover = (value) => {
    this.setState({
      starHoverValue: value,
    });
  };

  handleMouseLeaveValue = () => {
    this.setState({
      starHoverValue: undefined,
    });
  };

  onProceed = () => {
    const formData = new FormData();
    formData.append('reason', this.state.textarea);
    formData.append('stars', this.state.starValue);
    formData.append('reimbursementId', this.state.claimInfo.reimbursementId);
    formData.append('feedbackId', this.state.feedbackId.join(','));
    console.log('form', formData.values());
    for (var value of formData.values()) {
      console.log(value);
    }
    postFeedbackDetails(formData, this.props.authToken)
      .then((res) => {
        console.log('Submited', res);
      })
      .catch((err) => {
        console.log(err);
      });
    this.setState({
      success: true,
    });
  };

  onSelectedFeedback = (ele) => {
    console.log('a', ele);
    var selectedFeedId = ele.feedbackId;
    var value = [...this.state.feedbackId];
    if (value.includes(selectedFeedId)) {
      value = value.filter((ele) => ele !== selectedFeedId);
    } else {
      value.push(selectedFeedId);
    }
    console.log(value);
    this.setState({
      feedbackId: value,
    });
  };

  render() {
    const stars = Array(5).fill(0);
    return (
      <OuterContainer>
        {this.state.success ? (
          <div className="pt-60">
            <div className="flex flex-row justify-center items-center mb-20 p">
              <img src={FeedBackSubmitCheckIcon} />
            </div>
            <div className="flex flex-row justify-center items-center ">
              <span className="text-title-m ml-10px font-semibold p-64px text-txtlight">
                Thank you for your time!
              </span>
            </div>
            <div className="flex flex-row justify-center items-center">
              <p className="text-sub">
                We will make sure that you get better experience
              </p>
            </div>
          </div>
        ) : (
          <BodyContainer>
            <div className="p-10">
              <div className="rounded-12 border border-txtsecondary-shades7 ">
                <div className="flex flex-row items-center p-30 ml-10 ">
                  <img
                    src={HeadphonesIcon}
                    alt="Headphone"
                    height={'32px'}
                    width={'32px'}
                    style={{
                      height: '32px',
                      width: '32px',
                    }}
                  />
                  <div className="">
                    <span className="text-title-s ml-10px font-semibold p-64px text-txtlight">
                      Dr. Amit Mind
                    </span>
                    <div className="flex flex-row items-center">
                      <img
                        height={'10px'}
                        width={'10px'}
                        style={{
                          height: '20px',
                          width: '20px',
                        }}
                        className="mr-5 ml-10"
                        src={TimesCircleIcon}
                        alt="TimesCircleIcon"
                      />
                      <p
                        className="mr-10 txt-grey-600-11"
                        style={{ fontSize: '12px' }}
                      >
                        9:60 PM, June 28
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-center mb-20">
                    <span
                      className="font-semibold text-title-l"
                      style={{ color: '#302E6B' }}
                    >
                      Rate your consultation
                    </span>
                  </div>
                  <div
                    style={styles.stars}
                    className="flex flex-row justify-center items-center mb-20"
                  >
                    {stars.map((i, indx) => {
                      return (
                        <FaStar
                          key={indx}
                          size={50}
                          style={{ marginRight: 10, cursor: 'pointer' }}
                          onClick={() => {
                            this.handleClick(indx + 1);
                          }}
                          color={
                            (this.state.starHoverValue ||
                              this.state.starValue) > indx
                              ? colors.orange
                              : colors.grey
                          }
                          // onMouseOver={() => {
                          //   this.handleHover(indx + 1);
                          // }}
                          // onMouseLeave={() => {
                          //   this.handleMouseLeaveValue();
                          // }}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="mt-20 ml-5">
                <div className="mb-20">
                  <span className="text-title-s ml-10px font-semibold p-64px text-txtlight">
                    HELP US KNOW MORE
                  </span>
                </div>
                <div className="ml-15">
                  <div className="grid grid-cols-2 gap-2 mb-20">
                    {this.state.reason.map((ele, i) => {
                      return (
                        <div
                          className="rounded-12 border border-txtsecondary-shades7 text-center relative"
                          style={
                            this.state.feedbackId.includes(ele.feedbackId)
                              ? {
                                  border: '1px solid #c7222a ',
                                }
                              : {}
                          }
                        >
                          {this.state.feedbackId.includes(ele.feedbackId) ? (
                            <img
                              src={CheckIcon}
                              alt="checkIcon"
                              style={{
                                position: 'absolute',
                                top: '-40%',
                                right: '-2%',
                              }}
                            />
                          ) : (
                            ''
                          )}
                          <p
                            style={{ color: '#302E6B' }}
                            key={ele.feedbackId}
                            onClick={() => {
                              this.onSelectedFeedback(ele);
                            }}
                          >
                            {ele.reason}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="mt-20 mb-40">
                <textarea
                  name="body"
                  className="rounded-12 h-20 w-full p-4 outline-none border border-txtsecondary-shades7"
                  placeholder="Anything else you want to tell us? (optional)"
                  style={{
                    background: '#F0F3F6',
                  }}
                  onChange={(el) =>
                    this.setState({ textarea: el.target.value })
                  }
                />
              </div>
              <div>
                <PrimaryButton onTap={() => this.onProceed()}>
                  <ButtonText>Submit</ButtonText>
                </PrimaryButton>
              </div>
            </div>
          </BodyContainer>
        )}
      </OuterContainer>
    );
  }
}

const styles = {
  stars: {
    display: 'flex',
    flexDirection: 'row',
  },
};

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(ClaimFeedback);
