import React, { Component } from 'react';
import { connect } from 'react-redux';
import arrowLeft from '../../images/common/arrow-left.svg';
import discountIcon from '../../images/common/discount.svg';
import checkedIcon from '../../images/pharmacy/checked.svg';
import PaymentSuccessModal from './PaymentSuccess';
import supportIcon from '../../images/common/support1.svg';
import proceedIcon from '../../images/buying-journey/proceed.svg';
import config from '../../config';
import initializeFreshDesk from '../../utils/freshchat';
import { initializeAmplitudeClient } from '../../services/analytics';
import { logEvent } from '../../utils/logEvent';

export class BuyingJourneyPlans extends Component {
  state = {
    name: 'Aditya',
    plans: [
      {
        id: 1,
        planId: 1,
        subTermId: 1,
        duration: '3 months unlimited',
        debitDuration: '3 months',
        mrp: 999,
        cost: 999,
        recommended: false,
        footer: '',
      },
      {
        id: 2,
        planId: 1,
        subTermId: 2,
        duration: '6 months unlimited',
        debitDuration: '6 months',
        mrp: 1699,
        cost: 1699,
        recommended: false,
        footer: '',
      },
      {
        id: 3,
        planId: 1,
        subTermId: 3,
        duration: '12 months unlimited',
        debitDuration: '12 months',
        mrp: 1999,
        cost: 1999,
        recommended: true,
        footer: '',
      },
    ],
    selectedPlan: {
      id: 3,
      planId: 1,
      subTermId: 3,
      duration: '12 months unlimited',
      debitDuration: '12 months',
      mrp: 1999,
      cost: 1999,
      recommended: true,
      footer: '',
    },
    autoDebit: false,
    purchaseSuccess: false,
  };

  componentDidMount() {
    if (!this.props.authToken) {
      this.props.history.replace('/login?rt=buying-journey/plans');
    }
    this.amplitudeClient = initializeAmplitudeClient();
    this.amplitudeClient.init();
    logEvent('Visit Prime Plan Selection Screen');
  }

  hideModal = () => {
    this.setState({
      purchaseSuccess: false,
    });
  };

  openSupport = () => {
    initializeFreshDesk(this.props.user);
  };

  redirectToPaymentPage = () => {
    const { authToken } = this.props;
    const { autoDebit, selectedPlan } = this.state;
    window.location.href = `${config.apiBaseUrl}/subscription/transact?amount=${
      selectedPlan.cost
    }&subPlanTermId=${selectedPlan.subTermId}&autoDebit=${
      autoDebit ? 1 : 0
    }&auth=${authToken.split(' ')[1]}`;
  };

  render() {
    const { selectedPlan, name, plans, autoDebit, purchaseSuccess } =
      this.state;
    const { userName } = this.props;
    return (
      <div className="w-full overflow-auto">
        <div className="header p-16px pt-0 mt-52px border-b border-txtsecondary-shades4 flex flex-row justify-between">
          <img
            src={arrowLeft}
            alt="back"
            onClick={() => {
              if (
                this.props.location.state &&
                this.props.location.state.goBackTo
              ) {
                return this.props.history.replace(
                  '/buying-journey/tata-capital?utm_medium=tata_capital'
                );
              }
              this.props.history.replace('/buying-journey');
            }}
          />
          <span
            className="flex flex-row items-center justify-center"
            onClick={this.openSupport}
          >
            <img src={supportIcon} alt="support" />
            <p className="text-body-l ml-8px text-secondary-shades6 font-semibold">
              Need help ?
            </p>
          </span>
        </div>
        <div className="body pt-24px pl-20px pr-20px pb-200px">
          <p className="text-title-l text-txtlight font-bold">Hi {userName},</p>
          <p className="text-body-l font-regular text-txtsecondary">
            You and upto 5 family members are covered in this plan
          </p>
          <div className="mt-24px">
            {plans.map((plan) => {
              return (
                <div
                  className="mb-20px pt-12px pb-0 rounded-12"
                  onClick={() => {
                    logEvent('Visit Prime Plan Selected', {
                      termId: plan.subTermId,
                    });
                    this.setState({
                      selectedPlan: plan,
                    });
                  }}
                  style={
                    selectedPlan && selectedPlan.id === plan.id
                      ? { border: '2px solid #6C5DD380' }
                      : { border: '2px solid #F0F3F6' }
                  }
                >
                  {plan.recommended && (
                    <span className="bg-secondary-shades6 mt-12px rounded-8 m-16px pt-2px pb-2px pl-8px pr-8px text-chip text-textbox font-semibold">
                      Recommended
                    </span>
                  )}
                  <div className="flex flex-row justify-between items-center p-16px pt-12px pb-20px">
                    <div className="flex flex-row items-center">
                      {selectedPlan && selectedPlan.id === plan.id ? (
                        <div className="h-22px w-22px rounded-full bg-black bg-opacity-10 mr-12px flex flex-col items-center justify-center">
                          <div className="h-12px w-12px bg-primary rounded-full"></div>
                        </div>
                      ) : (
                        <div className="h-22px w-22px rounded-full bg-black bg-opacity-10 mr-12px"></div>
                      )}
                      <p className="text-body-l text-txtlight font-semibold">
                        {plan.duration}
                      </p>
                    </div>
                    <div className="flex flex-row items-center">
                      {/* <strike className="font-semibold text-body-l text-txtsecondary-shades8 mr-4px">
                        ₹ {plan.mrp}
                      </strike> */}
                      <p className="font-bold text-body-l font-txtlight">
                        ₹ {plan.cost}
                      </p>
                    </div>
                  </div>
                  {plan.footer && (
                    <div className="border-t border-dashed border-txtsecondary-shades7 pl-16px pr-16px pt-8px pb-8px flex flex-row">
                      <img src={discountIcon} alt="disc" />
                      <p className="text-chip text-txtsecondary font-semibold  ml-4px">
                        {plan.footer}
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {false && selectedPlan && (
            <div className="flex flex-row items-center">
              {autoDebit ? (
                <img
                  src={checkedIcon}
                  alt="checked"
                  height={'18px'}
                  width={'18px'}
                  onClick={() => {
                    this.setState({ autoDebit: false });
                  }}
                />
              ) : (
                <span
                  onClick={() => {
                    this.setState({ autoDebit: true });
                  }}
                  style={{ height: '18px', width: '18px' }}
                  className=" border border border-txtsecondary rounded-4"
                ></span>
              )}
              <p className="ml-8px text-txtsecondary font-semibold text-body-l">
                Debit ₹ {selectedPlan.cost} automatically every{' '}
                {selectedPlan.debitDuration} and renew my plan.
              </p>
            </div>
          )}
        </div>
        {this.state.selectedPlan && (
          <div
            className="footer absolute bottom-0 w-full p-24px bg-textbox"
            style={{ boxShadow: '0px -1px 20px rgba(0, 0, 0, 0.1)' }}
          >
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-col">
                {/* <span
                  className="flex flex-row items-center justify-center mb-4px"
                  onClick={this.openSupport}
                >
                  <img src={supportIcon} alt="support" />
                  <p className="text-body-l ml-8px text-secondary-shades6 font-semibold">
                    Chat with us
                  </p>
                </span> */}
                <p className="font-bold text-title-m font-txtlight">
                  ₹ {this.state.selectedPlan.cost}
                </p>
                {/* <strike className="font-semibold text-title-s text-txtsecondary-shades8">
                  ₹ {this.state.selectedPlan.mrp}
                </strike> */}
              </div>
              <span
                className="pl-32px pr-32px rounded-12 hover pt-12px pb-12px text-button-s flex flex-row items-center font-semibold text-textbox"
                style={{ background: '#1EB865', backdropFilter: 'blur(4px)' }}
                onClick={() =>
                  this.props.history.push('/buying-journey/preorder-summary', {
                    subTermId: selectedPlan.subTermId,
                    planId: selectedPlan.planId,
                    autoDebit: autoDebit,
                    selectedPlan: this.state.selectedPlan,
                  })
                }
              >
                Proceed
                <img src={proceedIcon} className="ml-8px h-16px w-16px" />
              </span>
            </div>
          </div>
        )}
        {this.state.purchaseSuccess && (
          <PaymentSuccessModal hideModal={this.hideModal} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  userName: state.user.userName,
  user: state.user,
});

export default connect(mapStateToProps)(BuyingJourneyPlans);
