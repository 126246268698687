import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CardContainer from '../common/CardContainer';
import { BoldText, Text } from '../common/Text';

const OuterCard = styled(CardContainer)`
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  align-self: stretch;
  margin: 12px 12px 20px 12px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 316px;
`;

const HeaderContainer = styled.div`
  padding: 24px 19px 19px 19px;
  border: 1px solid rgba(235, 235, 235, 0.5);
  display: flex;
  justify-content: space-between;
`;

const InsurerLogo = styled.img`
  height: 24px;
`;

const HeaderText = styled(BoldText)`
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #413958;
`;

const LabelText = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #373737;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 24px 0 24px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
`;

const Col = styled.div`
  flex: 1 0 auto;
  display: inline-block;
`;

const Label = styled(Text)`
  font-size: 10px;
  line-height: 12px;
  color: #666666;
`;

const SponsorLogo = styled.img`
  height: 24px;
`;

const OpdPolicyCard = ({ policyDetails }) => (
  <OuterCard>
    <HeaderContainer>
      <InsurerLogo src={policyDetails.insurerLogo} />
      <HeaderText>OPD E-Card</HeaderText>
    </HeaderContainer>
    <BodyContainer>
      <Row>
        <Col>
          <Label>Member Name</Label>
          <LabelText>{policyDetails.memberName}</LabelText>
        </Col>
      </Row>
      <Row>
        <Col>
          <Label>Age</Label>
          <LabelText>{policyDetails.age}</LabelText>
        </Col>
      </Row>
      <Row>
        {policyDetails.policyNumber && (
          <Col>
            <Label>Policy Number</Label>
            <LabelText>{policyDetails.policyNumber}</LabelText>
          </Col>
        )}
        <Col>
          <Label>Valid Till</Label>
          <LabelText>{policyDetails.validTill}</LabelText>
        </Col>
      </Row>
      <Row>
        <Col>
          <Label>Corporate Name</Label>
          <SponsorLogo src={policyDetails.sponsorLogo} />
        </Col>
      </Row>
    </BodyContainer>
  </OuterCard>
);

OpdPolicyCard.propTypes = {
  onTap: PropTypes.func,
};

export default OpdPolicyCard;
