import React, { Component } from 'react';
import { connect } from 'react-redux';
import arrowLeft from '../../images/common/arrow-left.svg';
import supportIcon from '../../images/common/support1.svg';
import infoIcon from '../../images/buying-journey/info.svg';
import membersIcon from '../../images/buying-journey/members.svg';
import calendarIcon from '../../images/buying-journey/calendar.svg';
import greenInfoIcon from '../../images/buying-journey/green-info.svg';
import config from '../../config';
import maleIcon from '../../images/buying-journey/male.png';
import femaleIcon from '../../images/buying-journey/female.png';
import sonIcon from '../../images/buying-journey/son.png';
import daughterIcon from '../../images/buying-journey/daughter.png';
import fatherIcon from '../../images/buying-journey/father.png';
import motherIcon from '../../images/buying-journey/mother.png';
import { getPreOrderSummary } from '../../services/buying-journey';
import initializeFreshDesk from '../../utils/freshchat';
import { initializeAmplitudeClient } from '../../services/analytics';
import { logEvent } from '../../utils/logEvent';

export class BuyingJourneyPlans extends Component {
  state = {
    orderInfo: null,
    memberInfo: [],
    autoDebit: false,
    coveredSelected: true,
    covered: [
      {
        title: 'Outpatient Consultations',

        descripton:
          'You are eligible for unlimited outpatient consultations by a general Medical Practitioner(s) or a specialist Medical Practitioner(s) during the subscription validation period.',
      },
      {
        title: 'Diagnostics',

        descripton:
          'You are eligible for unlimited outpatient diagnostic tests during the subscription validation period.',
      },
    ],
    notCovered: [
      {
        title: 'No Outpatient Consultations',

        descripton:
          'You are eligible for unlimited outpatient consultations by a general Medical Practitioner(s) or a specialist Medical Practitioner(s) during the subscription validation period.',
      },
      {
        title: 'No Diagnostics',

        descripton:
          'You are eligible for unlimited outpatient diagnostic tests during the subscription validation period.',
      },
    ],
  };

  componentDidMount() {
    const { history, location } = this.props;
    const state = location.state;
    if (state && state.planId && state.subTermId) {
      this.state.autoDebit = state.autoDebit;
      this.state.subTermId = state.subTermId;
      this.state.selectedPlan = state.selectedPlan;
      this.getPreOrderSummary(state.subTermId, state.planId);
      this.amplitudeClient = initializeAmplitudeClient();
      this.amplitudeClient.init();
      logEvent('Visit Prime Checkout Screen');
    } else {
      history.replace('/buying-journey');
    }
  }

  getPreOrderSummary = (subTermId, planId) => {
    getPreOrderSummary(subTermId, planId)
      .then((result) => {
        const orderInfo = result.orderInfo;
        const memberInfo = result.memberInfo;
        this.setState({
          orderInfo,
          memberInfo,
          planId: planId || this.state.planId,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  openSupport = () => {
    initializeFreshDesk(this.props.user);
  };

  redirectToPaymentPage = () => {
    const { authToken } = this.props;
    const { autoDebit, selectedPlan } = this.state;
    window.location.href = `${config.apiBaseUrl}/subscription/transact?amount=${
      selectedPlan.cost
    }&subPlanTermId=${selectedPlan.subTermId}&autoDebit=${
      autoDebit ? 1 : 0
    }&auth=${authToken.split(' ')[1]}`;
  };

  render() {
    const { memberInfo, orderInfo, coveredSelected, covered, notCovered } =
      this.state;
    const { gender } = this.props;
    const getRelationIcon = (relation) => {
      switch (relation) {
        case 'Self':
          if (gender && gender.toLowerCase() === 'male') {
            return maleIcon;
          } else {
            return femaleIcon;
          }
        case 'Husband':
          if (gender && gender.toLowerCase() === 'male') {
            return femaleIcon;
          } else {
            return maleIcon;
          }
        case 'Daughter':
          if (gender && gender.toLowerCase() === 'male') {
            return sonIcon;
          } else {
            return daughterIcon;
          }
        case 'Father':
          return fatherIcon;
        case 'Mother':
          return motherIcon;
        default:
          return false;
      }
    };
    return (
      <div className="w-full overflow-auto">
        <div className="header p-16px pt-0 mt-52px border-b border-txtsecondary-shades4 flex flex-row justify-between">
          <div className="flex flex-row items-center">
            <img
              src={arrowLeft}
              alt="back"
              onClick={() => {
                this.props.history.goBack();
              }}
            />
            <p className="text-title-m font-semibold text-txtlight ml-16px">
              Order Detail
            </p>
          </div>
          <span
            className="flex flex-row items-center justify-center"
            onClick={this.openSupport}
          >
            <img src={supportIcon} alt="support" />
            <p className="text-body-l ml-8px text-secondary-shades6 font-semibold">
              Need help ?
            </p>
          </span>
        </div>
        <div className="body mt-24px pb-200px">
          <div className="pl-20px pr-20px pb-32px bg-textbox">
            {orderInfo && (
              <div className="pt-8px">
                <div className="flex items-center mb-20px">
                  <img className="mr-8px" src={infoIcon} alt="Members" />
                  <p className="text-body-l text-txtlight font-semibold">
                    Order Info
                  </p>
                </div>
                <div className="border border-txtsecondary-shades4 rounded-xl px-20px mb-4px">
                  <div className="py-12px flex items-center justify-between border-b border-txtsecondary-shades4">
                    <p className="text-caption-l text-txtlight font-medium">
                      Subscription Plan
                    </p>
                    <p className="text-body-l text-txtlight font-semibold">
                      ₹ {orderInfo.coverCost}
                    </p>
                  </div>
                  <div className="py-12px flex items-center justify-between border-b border-txtsecondary-shades4">
                    <p className="text-caption-l text-txtlight font-medium">
                      GST
                    </p>
                    <p className="text-body-l text-txtlight font-semibold">
                      ₹ {orderInfo.gst}
                    </p>
                  </div>
                  <div className="py-12px flex items-center justify-between border-b border-txtsecondary-shades4">
                    <p className="text-body-l font-medium">Total Paid</p>
                    <p className="text-body-l text-txtlight font-semibold">
                      ₹ {orderInfo.coverCostInclGst}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="flex items-center mt-32px mb-4px">
              <img className="mr-8px" src={membersIcon} alt="Members" />
              <p className="text-body-l text-txtlight font-semibold">
                Covered Members
              </p>
            </div>
            <p className="text-body-l font-regular text-txtsecondary mb-16px">
              You can add upto 6 members in this plan
            </p>
            <div className="grid grid-cols-3 col-span-4 gap-4">
              {memberInfo.map((ele) => {
                if (ele === 'Wife' || ele === 'Son') {
                  return false;
                }
                let text = ele;
                if (ele === 'Husband') {
                  text = 'Spouse';
                }
                if (ele === 'Daughter') {
                  text = 'Children';
                }
                return (
                  <div className="p-10px flex items-center rounded-xl shadow-card border border-txtsecondary-shades4 ">
                    <img
                      className="w-24px mr-4px"
                      src={getRelationIcon(ele)}
                      alt={ele}
                    />
                    <span className="text-body-l text-txtlight font-semibold">
                      {text}
                    </span>
                  </div>
                );
              })}
            </div>
            {orderInfo && (
              <div className="pt-32px">
                <div className="flex items-center mb-16px">
                  <img className="mr-8px" src={calendarIcon} alt="Validity" />
                  <p className="text-body-l text-txtlight font-semibold">
                    Subscription Validity
                  </p>
                </div>
                <div className="bg-green-shades3 bg-opacity-10 rounded-xl">
                  <div className="p-16px">
                    <p className="text-body-l font-semibold text-txtlight">
                      {orderInfo.startDate} to {orderInfo.endDate}
                    </p>
                  </div>
                  <div className="py-8px px-16px border-t border-green-shades2 border-opacity-10 flex items-center">
                    <img className="mr-8px" src={greenInfoIcon} alt="Info" />
                    <p className="text-chip text-brochure font-medium">
                      {this.state.autoDebit
                        ? 'Your plan is set to auto renew at the end of your current cycle'
                        : 'Your plan will not auto renew. Your benefits will stop at the end of your current cycle'}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <div className="rounded-12 pl-20px pr-20px pt-16px pb-32px">
            <div className="border border-txtsecondary-shades7 rounded-12px flex flex-row justify-between p-4px rounded-12">
              <span
                className="pt-4px pb-4px text-center font-semibold text-body-l"
                onClick={() => {
                  this.setState({ coveredSelected: true });
                }}
                style={
                  coveredSelected
                    ? {
                        background: '#ffffff',
                        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        width: '50%',
                        color: '#0f0b2b',
                      }
                    : { width: '50%', color: '#585969' }
                }
              >
                Whats Covered
              </span>
              <span
                className="pt-4px pb-4px text-center font-semibold text-body-l"
                onClick={() => {
                  this.setState({ coveredSelected: false });
                }}
                style={
                  !coveredSelected
                    ? {
                        background: '#ffffff',
                        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        width: '50%',
                        color: '#0f0b2b',
                      }
                    : { width: '50%', color: '#585969' }
                }
              >
                Whats Not Covered
              </span>
            </div>
            <div className="mt-32px">
              {coveredSelected
                ? covered.map((ele, idx) => {
                    return (
                      <div>
                        <p className="text-txtlight font-semibold title-s">
                          {idx + 1}. {ele.title}
                        </p>
                        <p className="pt-16px pb-16px text-txtlight font-regular text-body-l">
                          {ele.descripton}
                        </p>
                      </div>
                    );
                  })
                : notCovered.map((ele, idx) => {
                    return (
                      <div>
                        <p className="text-txtlight font-semibold title-s">
                          {idx + 1}. {ele.title}
                        </p>
                        <p className="pt-16px pb-16px text-txtlight font-regular text-body-l">
                          {ele.descripton}
                        </p>
                      </div>
                    );
                  })}
            </div>
          </div> */}
        </div>

        {orderInfo && (
          <div
            className="footer absolute bottom-0 w-full p-24px bg-textbox"
            style={{ boxShadow: '0px -1px 20px rgba(0, 0, 0, 0.1)' }}
          >
            <div className="">
              <div
                className="pl-16px pr-24px flex flex-row items-center justify-between rounded-12 hover py-16px text-button-s font-semibold text-textbox bg-primary w-full text-center"
                onClick={this.redirectToPaymentPage}
              >
                <p>₹ {orderInfo.coverCostInclGst}</p>
                <p>Proceed to pay</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  gender: state.user.userGender,
  user: state.user,
});

export default connect(mapStateToProps)(BuyingJourneyPlans);
