import React, { Component } from 'react';
import styled from 'styled-components';
import { FaCheck, FaTimes } from 'react-icons/fa';

import Modal from '../../containers/common/Modal';
import { FlyInTransition } from '../../components/transitions/transitions';
import StyledTappable from '../../components/common/StyledTappable';

const AddContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border: 2px solid #c7222a;
  border-radius: 50%;
`;

const AddedContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background: #c7222a;
  border: 2px solid #c7222a;
  border-radius: 50%;
`;

const AddText = styled.div`
  color: #c7222a;
  height: 10px;
  width: 10px;
`;

const AddedIcon = styled(FaCheck)`
  color: #ffffff;
  height: 10px;
  width: 10px;
`;

const CloseContainer = styled(StyledTappable)`
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 8px;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseIcon = styled(FaTimes)`
  color: #413958;
`;

export class RelativeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }

  hideModal = () => {
    this.setState({ showModal: false });
    setTimeout(this.props.hideModal, 200);
  };
  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.hideModal();
  };
  render() {
    const { relatives } = this.props;
    const renderRelatives = (ele) => {
      return (
        <div
          className="patient-container"
          key={ele.relativeId}
          onClick={() => {
            this.props.selectRelative(ele);
            this.hideModal();
          }}
        >
          {this.props.selectedRelative &&
          ele.id === this.props.selectedRelative.id ? (
            <AddedContainer>
              <AddedIcon />
            </AddedContainer>
          ) : (
            <AddContainer>
              <AddText />
            </AddContainer>
          )}
          <div className="txt-black-600-12 pl-12 flex-col">
            <span>{ele.name}</span>
            <span>
              {ele.age},{ele.gender}
            </span>
            <span className="txt-grey-10">{ele.phone}</span>
          </div>
        </div>
      );
    };
    return (
      <Modal>
        <div className="modalContainer" onClick={this.handleOutsideClick}>
          <FlyInTransition
            in={this.state.showModal}
            mountOnEnter
            unmountOnExit
            onExited={this.hideModal}
            appear
          >
            <div className="modalBody" ref={(node) => (this.node = node)}>
              <div className="modalHeader">
                Select Patient
                <CloseContainer onTap={this.hideModal}>
                  <CloseIcon />
                </CloseContainer>
              </div>
              <div className="modalDetails">
                {relatives.map(renderRelatives)}
              </div>
            </div>
          </FlyInTransition>
        </div>
      </Modal>
    );
  }
}

export default RelativeSelect;
