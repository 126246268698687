/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { RegularText } from '../../common/Text';
import StyledTappable from '../../common/StyledTappable';
import { FlyInTransition } from '../../transitions/transitions';
import Modal from '../../../containers/common/Modal';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  max-width: 80%;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  margin: 0.25rem 0;
`;

const BubbleContainer = styled(StyledTappable)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0.25rem;
  background: rgba(228, 220, 248, 0.2);
  border-radius: 0.875rem;
`;

const Image = styled.div`
  background-size: cover;
  width: 12rem;
  height: 12rem;
  border-radius: 0.75rem;
  background-image: ${(props) => `url(${props.url})`};
  background-repeat: no-repeat;
`;

const TimestampContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
`;

const TickIcon = styled(FaCheck)`
  color: #fff;
  opacity: 0.5;
  font-size: ${() => `${0.625 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-right: 0.25rem;
`;

const TimestampText = styled(RegularText)`
  color: #fff;
  opacity: 0.5;
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const CloseContainer = styled(StyledTappable)`
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 8px;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseIcon = styled(FaTimes)`
  color: #ffffff;
`;

const UserImageMessage = ({ filePreviewUrl, url, messageId, timestamp }) => {
  const [clicked, setClicked] = useState(false);

  const areaNode = useRef(null);

  const hideModal = () => {
    setTimeout(() => {
      setClicked(false);
    }, 200);
  };
  const handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (areaNode && areaNode.current === e.target) {
      return;
    }
    hideModal();
  };
  return (
    <OuterContainer>
      <BubbleContainer
        onTap={() => {
          // document.querySelector(`#${messageId}`).click();
          setClicked(true);
        }}
      >
        <Image url={filePreviewUrl} />
        {timestamp && (
          <TimestampContainer>
            <TickIcon />
            <TimestampText>
              {new Date(timestamp).toLocaleTimeString('nu', {
                hour: 'numeric',
                minute: '2-digit',
              })}
            </TimestampText>
          </TimestampContainer>
        )}
      </BubbleContainer>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        id={messageId}
        style={{ display: 'none' }}
      />
      {clicked && (
        <Modal>
          <div className="modalContainer" onClick={handleOutsideClick}>
            <FlyInTransition
              in={clicked}
              mountOnEnter
              unmountOnExit
              onExited={hideModal}
              appear
            >
              <div
                className="w-96 p-24px h-full"
                style={{ marginTop: '35%'}}
                ref={(node) => (areaNode.current = node)}
              >
                <div
                  className="flex mb-12px w-full justify-end"
                >
                  <CloseContainer onTap={hideModal}>
                    <CloseIcon />
                  </CloseContainer>
                </div>
                <div className="">
                  <img src={url} style={{ width: '100%', height: '100%' }} />
                </div>
              </div>
            </FlyInTransition>
          </div>
        </Modal>
      )}
    </OuterContainer>
  );
};

UserImageMessage.propTypes = {
  filePreviewUrl: PropTypes.string,
  url: PropTypes.string,
  messageId: PropTypes.string,
  timestamp: PropTypes.number,
};

export default UserImageMessage;
