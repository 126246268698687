import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import arrowLeft from '../../images/common/arrow-left.svg';
import discountIcon from '../../images/common/discount.svg';
import checkedIcon from '../../images/pharmacy/checked.svg';
import PaymentSuccessModal from './PaymentSuccess';
import SubmitConfirmModal from './SubmitConfirm';
import Modal from '../../containers/common/Modal';
import { FlyInTransition } from '../../components/transitions/transitions';
import supportIcon from '../../images/common/support1.svg';
import infoIcon from '../../images/buying-journey/info.svg';
import successIcon from '../../images/buying-journey/success.svg';
import membersIcon from '../../images/buying-journey/members.svg';
import detailsIcon from '../../images/buying-journey/details.svg';
import calendarIcon from '../../images/buying-journey/calendar.svg';
import crossIcon from '../../images/common/cross.svg';
import greenInfoIcon from '../../images/buying-journey/green-info.svg';
import maleIcon from '../../images/buying-journey/male.png';
import femaleIcon from '../../images/buying-journey/female.png';
import sonIcon from '../../images/buying-journey/son.png';
import daughterIcon from '../../images/buying-journey/daughter.png';
import fatherIcon from '../../images/buying-journey/father.png';
import motherIcon from '../../images/buying-journey/mother.png';
import config from '../../config';
import { addRelative, getOrderSummary } from '../../services/buying-journey';
import initializeFreshDesk from '../../utils/freshchat';
import { initializeAmplitudeClient } from '../../services/analytics';
import { logEvent } from '../../utils/logEvent';

class AddMemberModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: true,
      name: '',
      dobDate: '',
      dobMonth: '',
      dobYear: '',
      mobileNumber: '',
      gender: props.gender || '',
    };
    this.dateNodeRef = React.createRef();
    this.monthNodeRef = React.createRef();
    this.yearNodeRef = React.createRef();
    this.mobileNodeRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { showModal } = this.props;
    if (prevProps.showModal && !showModal) {
      this.hideModal();
    }
    if (!prevProps.showModal && showModal) {
      this.setState({
        showModal: true,
      });
    }
  }

  hideModal = () => {
    this.setState({
      name: '',
      dobDate: '',
      dobMonth: '',
      dobYear: '',
      gender: '',
      mobileNumber: '',
    });
    this.setState({ showModal: false });
    setTimeout(this.props.hideModal, 200);
  };

  handleOutsideClick = (e) => {
    if (this.modalNode && this.modalNode.contains(e.target)) {
      return;
    }
    this.hideModal();
  };

  addRelative = () => {
    const { name, dobDate, dobMonth, dobYear, gender, mobileNumber } =
      this.state;
    const { relation } = this.props;
    this.props.addRelative({
      name,
      dateOfBirth: `${dobDate}-${dobMonth}-${dobYear}`,
      mobileNumber,
      relation,
      gender,
    });
  };

  render() {
    return (
      <Modal>
        <div className="modalContainer" onClick={this.handleOutsideClick}>
          <FlyInTransition
            in={this.state.showModal}
            mountOnEnter
            unmountOnExit
            appear
          >
            <div
              className="bg-textbox absolute bottom-0px w-full rounded-t-3xl max-h-full overflow-auto"
              ref={(node) => (this.modalNode = node)}
            >
              <div className="p-20 border-b border-txtsecondary-shades7 border-solid flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                  <img src={membersIcon} alt="home" />
                  <span className="text-title-s ml-10px font-semibold text-txtlight">
                    {this.props.relation} Details
                  </span>
                </div>
                <img src={crossIcon} alt="cross" onClick={this.hideModal} />
              </div>
              <div className="p-20 max-h-full overflow-auto">
                <div>
                  <p className="text-body-l text-txtlight font-medium">
                    Full Name
                  </p>
                  <input
                    className="mt-8px new-address-input text-title-s font-semibold"
                    placeholder="Enter name here"
                    value={this.state.name}
                    onChange={(e) => {
                      this.setState({
                        name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="mt-24px">
                  <p className="text-body-l text-txtlight font-medium">
                    Date of Birth
                  </p>
                  <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-1">
                      <input
                        className="mt-8px new-address-input text-title-s font-semibold text-center"
                        placeholder="dd"
                        value={this.state.dobDate}
                        maxLength="2"
                        ref={this.dateNodeRef}
                        onChange={(e) => {
                          if (e.target.value.length === 2) {
                            this.monthNodeRef.current &&
                              this.monthNodeRef.current.focus();
                          }
                          this.setState({
                            dobDate: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-span-1">
                      <input
                        className="mt-8px new-address-input text-title-s font-semibold text-center"
                        placeholder="mm"
                        maxLength="2"
                        ref={this.monthNodeRef}
                        value={this.state.dobMonth}
                        onChange={(e) => {
                          if (e.target.value.length === 2) {
                            this.yearNodeRef.current &&
                              this.yearNodeRef.current.focus();
                          }
                          this.setState({
                            dobMonth: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-span-2">
                      <input
                        className="mt-8px new-address-input text-title-s font-semibold text-center"
                        placeholder="yyyy"
                        maxLength="4"
                        ref={this.yearNodeRef}
                        value={this.state.dobYear}
                        onChange={(e) => {
                          if (e.target.value.length === 4) {
                            this.mobileNodeRef.current &&
                              this.mobileNodeRef.current.focus();
                          }
                          this.setState({
                            dobYear: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-24px">
                  <p className="text-body-l text-txtlight font-medium">
                    Mobile Number (Optional)
                  </p>
                  <input
                    className="mt-8px new-address-input text-title-s font-semibold"
                    placeholder="Your 10 digit valid number"
                    value={this.state.mobileNumber}
                    ref={this.mobileNodeRef}
                    onChange={(e) => {
                      this.setState({
                        mobileNumber: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="mt-24px">
                  <p className="text-body-l text-txtlight font-medium">
                    Gender
                  </p>
                  <div className="grid grid-cols-2 gap-4 w-full justify-between mt-8px">
                    <span
                      className="text-center relative pt-8px pb-8px border border-txtsecondary-shades7 border-solid rounded-12 pr-24px pl-24px"
                      onClick={() => {
                        this.setState({
                          gender: 'Male',
                        });
                      }}
                      style={
                        this.state.gender === 'Male'
                          ? { borderColor: '#6C5DD3' }
                          : {}
                      }
                    >
                      <p
                        className="text-body-l font-regular"
                        style={
                          this.state.gender === 'Male'
                            ? { color: '#6C5DD3', fontWeight: '600' }
                            : { color: '#302E6B' }
                        }
                      >
                        Male
                      </p>
                      {this.state.addressType === 'home' && (
                        <span className="absolute -top-10px -right-10px">
                          <img src={checkedIcon} alt="tick" />
                        </span>
                      )}
                    </span>
                    <span
                      className="text-center relative pt-8px pb-8px border border-txtsecondary-shades7 border-solid rounded-12 pr-24px pl-24px"
                      onClick={() => {
                        this.setState({
                          gender: 'Female',
                        });
                      }}
                      style={
                        this.state.gender === 'Female'
                          ? { borderColor: '#6C5DD3' }
                          : {}
                      }
                    >
                      <p
                        className="text-body-l font-regular"
                        style={
                          this.state.gender === 'Female'
                            ? { color: '#6C5DD3', fontWeight: '600' }
                            : { color: '#302E6B' }
                        }
                      >
                        Female
                      </p>
                      {this.state.addressType === 'work' && (
                        <span className="absolute -top-10px -right-10px">
                          <img src={checkedIcon} alt="tick" />
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              {this.state.name.length > 0 &&
                this.state.gender &&
                this.state.dobDate.length > 0 &&
                this.state.dobMonth.length > 0 &&
                this.state.dobYear.length > 0 && (
                  <div
                    className="pt-42px pl-20px pr-20px pb-32px w-full"
                    style={{
                      background:
                        'linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 31.77%, #FFFFFF 66.15%, rgba(255, 255, 255, 0) 100%)',
                    }}
                  >
                    <div
                      className="bg-primary rounded-12 pt-12px pb-10px center"
                      onClick={this.addRelative}
                    >
                      {!this.props.adding ? (
                        <>
                          <span className="text-button-s font-bold text-textbox mr-10px">
                            Add
                          </span>
                        </>
                      ) : (
                        <span className="text-button-s font-bold text-textbox mr-10px">
                          Loading....
                        </span>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </FlyInTransition>
        </div>
      </Modal>
    );
  }
}

export class BuyingJourneyPlans extends Component {
  state = {
    orderInfo: null,
    memberInfo: [],
    relations: [],
    purchaseSuccess: false,
    showAddMemberModal: false,
    addingRelation: '',
    addingRelationGender: '',
    transactionId: null,
    submitConfirm: false,
  };

  componentDidMount() {
    const { history, location } = this.props;
    this.amplitudeClient = initializeAmplitudeClient();
    this.amplitudeClient.init();
    logEvent('Visit Prime Order Completion Screen');
    if (location.search) {
      const params = new URLSearchParams(location.search);
      if (params.get('success') && params.get('success') === 'true') {
        const transactionId = params.get('transactionId');
        this.getOrderSummary(transactionId);
        this.setState(
          {
            purchaseSuccess: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                purchaseSuccess: false,
              });
            }, 2000);
          }
        );
      } else {
        history.replace('/buying-journey');
      }
    } else {
      history.replace('/buying-journey');
    }
  }

  getOrderSummary = (transactionId) => {
    getOrderSummary(transactionId || this.state.transactionId)
      .then((result) => {
        const orderInfo = result.orderInfo;
        const memberInfo = result.memberInfo;
        const relations = result.coveredRelations;
        this.setState({
          orderInfo,
          memberInfo,
          transactionId: transactionId || this.state.transactionId,
          relations,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  hideModal = () => {
    this.setState({
      purchaseSuccess: false,
      showAddMemberModal: false,
    });
  };

  openSupport = () => {
    initializeFreshDesk(this.props.user);
  };

  redirectToPaymentPage = () => {
    const { authToken } = this.props;
    const { autoDebit, selectedPlan } = this.state;
    window.location.href = `${config.apiBaseUrl}/subscription/transact?amount=${
      selectedPlan.cost
    }&subPlanTermId=${selectedPlan.subTermId}&autoDebit=${
      autoDebit ? 1 : 0
    }&auth=${authToken.split(' ')[1]}`;
  };

  addRelative = (relativeDetails) => {
    const { transactionId } = this.state;
    addRelative(relativeDetails, transactionId)
      .then((res) => {
        this.hideModal();
        this.getOrderSummary();
        logEvent('Visit Prime Relative Saved');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showAddRelativeModal = (relation, gender) => () => {
    logEvent('Visit Prime Add Relative');
    this.setState({
      showAddMemberModal: true,
      addingRelation: relation,
      addingRelationGender: gender,
    });
  };

  showFinalConfirmation = () => {
    logEvent('Visit Prime Submission Complete');
    this.setState(
      {
        submitConfirm: true,
      },
      () => {
        setTimeout(() => {
          if (typeof window.Android !== 'undefined') {
            return window.Android.reloadApp();
          } else {
            this.props.history.push('/buying-journey/success');
          }
        }, 4000);
      }
    );
  };

  render() {
    const {
      selectedPlan,
      name,
      plans,
      autoDebit,
      purchaseSuccess,
      memberInfo,
      relations,
      orderInfo,
    } = this.state;
    const selfInfo = memberInfo.find((ele) => ele.relation === 'Self');
    const maxMembersAdded = memberInfo.filter((ele) => ele.name).length >= 6;
    const getRelationIcon = (relation, gender) => {
      switch (relation) {
        case 'Self':
          if (gender && gender.toLowerCase() === 'male') {
            return maleIcon;
          } else {
            return femaleIcon;
          }
        case 'Spouse':
          if (gender && gender.toLowerCase() === 'male') {
            return maleIcon;
          } else {
            return femaleIcon;
          }
        case 'Child':
          if (gender && gender.toLowerCase() === 'male') {
            return sonIcon;
          } else {
            return daughterIcon;
          }
        case 'Father':
          return fatherIcon;
        case 'Mother':
          return motherIcon;
        default:
          return femaleIcon;
      }
    };
    const renderAddMemberButton = (relation, gender, key) => {
      return (
        <div
          key={key}
          onClick={this.showAddRelativeModal(relation, gender)}
          className="cursor-pointer p-16px mb-16px flex items-center justify-between w-100 rounded-xl shadow-card border border-txtsecondary-shades4"
        >
          <div className="flex items-center">
            <img
              className="w-32px mr-8px"
              src={getRelationIcon(relation, gender)}
              alt={relation}
            />
            <p className="text-body-l text-txtlight font-semibold">
              {relation}
            </p>
          </div>
          <div className="flex items-center">
            <img src={detailsIcon} alt="Add" />
            <p className="text-caption-l text-primary font-semibold">
              Add Details
            </p>
          </div>
        </div>
      );
    };
    const renderMemberDetails = (ele, idx) => {
      if (ele.name) {
        const age = moment().diff(moment(ele.dateOfBirth), 'years');
        return (
          <div
            key={idx}
            className="p-16px mb-16px w-100 rounded-xl shadow-card"
          >
            <div className="flex items-center pb-8px mb-12px border-b border-txtsecondary-shades4">
              <img
                className="w-32px mr-8px"
                src={getRelationIcon(ele.relation, ele.gender)}
                alt={ele.relation}
              />
              <p className="text-body-l text-txtlight font-semibold">
                {ele.relation}
              </p>
            </div>
            <p className="text-body-l text-txtlight font-semibold">
              {ele.name}, <span className="font-medium">{age}</span>
            </p>
            <p className="text-body-l text-txtlight font-medium">
              {!ele.phone || ele.phone === 0
                ? 'No phone number added'
                : ele.phone}
            </p>
          </div>
        );
      } else {
        // Check whether this should be shown or not
        // Do not show any buttons if 6 members have been added
        if (memberInfo.filter((ele) => ele.name).length === 6) {
          return false;
        }
        if (
          memberInfo.filter((member) => member.relation === ele.title).length >
          0
        ) {
          return false;
        }
        if (
          ['Wife', 'Husband'].indexOf(ele.relation) !== -1 &&
          ele.gender === selfInfo.gender
        ) {
          return false;
        }
        if (['Son', 'Daughter'].indexOf(ele.relation) !== -1) {
          return false;
        }
        return renderAddMemberButton(ele.relation, ele.gender, idx);
      }
    };
    const renderAddRelativeButtons = (ele, idx) => {
      console.log(ele);
      if (maxMembersAdded) {
        return false;
      }
      if (
        memberInfo.filter((member) => member.relation === ele.title).length > 0
      ) {
        return false;
      }
      if (
        ['Wife', 'Husband'].indexOf(ele.title) !== -1 &&
        ele.gender === selfInfo.gender
      ) {
        return false;
      }
      if (['Son', 'Daughter'].indexOf(ele.title) !== -1) {
        return false;
      }
      if (['Wife', 'Husband'].indexOf(ele.title) !== -1) {
        return renderAddMemberButton('Spouse', ele.gender, idx);
      }
      return renderAddMemberButton(ele.title, ele.gender, idx);
    };
    const renderAdditionalAddRelativeButtons = () => {
      const areChildrenAllowed =
        relations.filter(
          (ele) => ele.title === 'Son' || ele.title === 'Daughter'
        ).length > 0;
      if (areChildrenAllowed && !maxMembersAdded) {
        return renderAddMemberButton('Child');
      }
    };
    return (
      <div className="w-full overflow-auto">
        <div className="header p-16px pt-0 mt-52px border-b border-txtsecondary-shades4 flex flex-row justify-between">
          <p className="text-title-m text-txtlight font-semibold">
            Order Summary
          </p>
        </div>
        <div className="body pt-24px pl-20px pr-20px pb-200px">
          <div className="bg-green-shades4 rounded-xl mb-32px bg-opacity-10 flex items-center p-16px">
            <img className="mr-8px" src={successIcon} alt="Success" />
            <p className="text-body-l text-txtlight font-semibold">
              Plan Purchased Successfully!
            </p>
          </div>
          <div className="flex items-center mb-20px">
            <img className="mr-8px" src={membersIcon} alt="Members" />
            <p className="text-body-l text-txtlight font-semibold">
              Covered Members
            </p>
          </div>
          {memberInfo.map(renderMemberDetails)}
          {relations.map(renderAddRelativeButtons)}
          {renderAdditionalAddRelativeButtons()}
          {orderInfo && (
            <div className="pt-16px">
              <div className="flex items-center mb-20px">
                <img className="mr-8px" src={infoIcon} alt="Members" />
                <p className="text-body-l text-txtlight font-semibold">
                  Order Info
                </p>
              </div>
              <div className="border border-txtsecondary-shades4 rounded-xl px-20px mb-4px">
                <div className="py-12px flex items-center justify-between border-b border-txtsecondary-shades4">
                  <p className="text-caption-l text-txtlight font-medium">
                    Subscription Plan
                  </p>
                  <p className="text-body-l text-txtlight font-semibold">
                    ₹ {orderInfo.coverCost}
                  </p>
                </div>
                <div className="py-12px flex items-center justify-between border-b border-txtsecondary-shades4">
                  <p className="text-caption-l text-txtlight font-medium">
                    GST
                  </p>
                  <p className="text-body-l text-txtlight font-semibold">
                    ₹ {orderInfo.gst}
                  </p>
                </div>
                <div className="py-12px flex items-center justify-between border-b border-txtsecondary-shades4">
                  <p className="text-body-l font-medium">Total Paid</p>
                  <p className="text-body-l text-txtlight font-semibold">
                    ₹ {orderInfo.coverCostInclGst}
                  </p>
                </div>
              </div>
            </div>
          )}
          {orderInfo && (
            <div className="pt-16px">
              <div className="flex items-center mb-20px">
                <img className="mr-8px" src={calendarIcon} alt="Validity" />
                <p className="text-body-l text-txtlight font-semibold">
                  Subscription Validity
                </p>
              </div>
              <div className="bg-green-shades3 bg-opacity-10 rounded-xl">
                <div className="p-16px">
                  <p className="text-body-l font-semibold text-txtlight">
                    {orderInfo.startDate} to {orderInfo.endDate}
                  </p>
                </div>
                <div className="py-8px px-16px border-t border-green-shades2 border-opacity-10 flex items-center">
                  <img className="mr-8px" src={greenInfoIcon} alt="Info" />
                  <p className="text-chip text-brochure font-medium">
                    {orderInfo.autoDebit
                      ? 'Your plan is set to auto renew at the end of your current cycle'
                      : 'Your plan will not auto renew. Your benefits will stop at the end of your current cycle'}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="footer absolute bottom-0 w-full p-24px bg-textbox"
          style={{ boxShadow: '0px -1px 20px rgba(0, 0, 0, 0.1)' }}
        >
          <div className="flex flex-row items-center justify-between">
            <div
              className="rounded-12 hover py-16px text-button-s font-semibold text-textbox bg-primary w-full text-center"
              onClick={this.showFinalConfirmation}
            >
              Submit Details
            </div>
          </div>
        </div>
        {this.state.purchaseSuccess && (
          <PaymentSuccessModal hideModal={this.hideModal} />
        )}
        {this.state.submitConfirm && <SubmitConfirmModal />}
        {this.state.showAddMemberModal && (
          <AddMemberModal
            showModal={this.state.showAddMemberModal}
            relation={this.state.addingRelation}
            gender={this.state.addingRelationGender}
            hideModal={this.hideModal}
            addRelative={this.addRelative}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  user: state.user,
});

export default connect(mapStateToProps)(BuyingJourneyPlans);
