import React from 'react';
import styled from 'styled-components';
import { Input } from 'valuelink/lib/tags';

const OuterContainer = styled.div`
  display: flex;
  align-items: stretch;
  align-self: stretch;
`;

const InputBox = styled(Input)`
  padding: 16px;
  display: flex;
  align-self: stretch;
  flex: 1;
  ::placeholder {
    color: #bababa;
  }
`;

const StringInput = (props) => {
  const { numeric, value } = props;
  return (
    <OuterContainer className="font-pfHand">
      <InputBox
        valueLink={value}
        type={numeric ? 'tel' : 'text'}
        className="new-address-input text-title-s font-semibold border border-txtsecondary-shades7"
        placeholder="Type your message here"
      />
    </OuterContainer>
  );
};

export default StringInput;
